import * as React from "react"
import {GeneralContext} from "../../core/general.context"
import * as yup from "yup"
import {LabelContext} from "../../core/label.context"
import {useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {CheckoutContext} from "../../core/checkout.context"
import {LoginPopup} from "./login.component"
import {PROJECT_COUNTRY} from "../../core/APIUtils"
import {InputElement} from "../input/input.component";

interface FormValues {
    email: string
    password?: string
    profiling?: boolean
}

export const Authentication = React.forwardRef((props: any, ref) => {
    const [email, setEmail] = React.useState("")
    const { userData } = React.useContext(GeneralContext)
    const { cart } = React.useContext(GeneralContext)
    const { t } = React.useContext(LabelContext)
    const { makeSignUp, setMakeSignUp } = React.useContext(CheckoutContext)

    let requiredError = t("ecommerce.form-messagge.required")
    const getValidationSchema = (makeSignUp: boolean) => {
        return yup.object().shape({
            email: yup.string().email("Invalid email address").required(requiredError).trim(),
            password: makeSignUp
                ? yup.string().required(requiredError).min(6, "Password must be at least 6 characters")
                : yup.string().optional(),
            profiling: yup.bool().when({
            is: () => ["DE"].includes(PROJECT_COUNTRY),
            then: yup.bool().oneOf([true, false])
        })
        });
    };

    const validationSchema = getValidationSchema(makeSignUp);

    const { register, formState: { errors }, setValue, trigger, getValues, reset } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: email,
            password: "",
            profiling: false,
        },
    });

    React.useImperativeHandle(ref, () => ({
        authenticationFormValidate: () => trigger(),
        authenticationFormGetData: () => getValues(),
        authenticationFormReset: () => reset()
    }))

    React.useEffect(() => {
        if(PROJECT_COUNTRY === 'BE'){
            setMakeSignUp(true)
        }
    },[])

    React.useEffect(() => {
        if(cart && cart.customerEmail && cart.customerEmail !== ""){
            setEmail(cart.customerEmail)
        }
    }, [cart]);

    React.useEffect(() => {
        setValue("email", email)
    }, [email, setValue]);

    React.useEffect(() => {
        setValue("password", "")
    }, [makeSignUp, setValue])

    React.useEffect(() => {
        getValidationSchema(makeSignUp);
        setValue("password", ""); // Reset the password field if makeSignUp changes
    }, [makeSignUp, setValue]);

    let registrationMessage = t("ecommerce.checkout.registration-title")
    let emailNotificationMessage = t("ecommerce.form-email-notification-message")

    return (
        <div className="checkout-column-main">
            <div className="">
                <LoginPopup openPopupDefault={false} subtitle={""}/>
                {registrationMessage && !userData && <h4 className="mb-2">{registrationMessage}</h4>}
                <form className="checkout-form">
                    <div>
                        {/*<label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>{t("ecommerce.signup.email")}</label>
                        <input
                            {...register("email")}
                            type="email"
                            id="email"
                            name="email"
                            className="form-control-bootstrap"
                        />
                        {errors.email && <div className="error message--alert small">{errors.email.message}</div>}*/}
                        <InputElement
                            label={t("ecommerce.signup.email")}
                            placeholder={t("ecommerce.signup.email")}
                            id="email"
                            externalRegister={register("email")}
                            errors={errors.email?.message}
                        />
                    </div>
                    {emailNotificationMessage &&
                        <div className="email-information mb-3">
                            {emailNotificationMessage}
                        </div>
                    }
                    {PROJECT_COUNTRY !== 'BE' && (
                        <div className="checkboxButton" style={{ marginRight: '10px' }}>
                            <input
                                type="checkbox"
                                id="makeSignUp"
                                name="makeSignUp"
                                checked={makeSignUp}
                                onChange={() => { setMakeSignUp(!makeSignUp); }}
                            />
                            <label htmlFor="makeSignUp">
                                {t("ecommerce.form-email-create-account")}
                            </label>
                        </div>
                    )}
                    {makeSignUp && (
                        <>
                        {/*<div>
                            <label htmlFor="password" style={{ display: 'block', marginBottom: '5px' }}>{t("ecommerce.signup.password")}</label>
                            <input
                                {...register("password")}
                                type="password"
                                id="password"
                                name="password"
                                className="form-control-bootstrap"
                            />
                            {errors.password && <div style={{ color: 'red' }}>{errors.password.message}</div>}
                        </div>*/}
                            <InputElement
                                type="password"
                                label={t("ecommerce.signup.password")}
                                placeholder={t("ecommerce.signup.password")}
                                id="password"
                                externalRegister={register("password")}
                                errors={errors.password?.message}
                            />
                        {["DE"].includes(PROJECT_COUNTRY) &&
                            <div className="checkboxButton">
                                <>
                                    <input type="checkbox" value="1" {...register("profiling")} name="profiling" id="profiling" />{" "}
                                    <label
                                        htmlFor="profiling"
                                        dangerouslySetInnerHTML={{ __html: t("ecommerce.signup.profiling-flag") }}
                                    />
                                </>
                            </div>
                        }
                        </>
                    )}
                </form>
            </div>
        </div>
    )
})