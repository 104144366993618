import * as React from "react";
import { RESTContext } from "../../core/rest.context"
import ReactDOM from "react-dom"
import { calls, PROJECT_LANGUAGE } from "../../core/APIUtils"
import { BundleItem } from "../sliders/slideritems.component"
import { StyledBundlesCategory } from "./bundle.style"
import {
    isBundleAvailable,
    getBundlePrice,
    customDiscountLabel, removeBundleCancelledFromProductProjection
} from "./utils"
import {GeneralContext} from "../../core/general.context";


export const BundleListComponent = (props: any) => {
    const { ajax$ } = React.useContext(RESTContext);
    const { userData } = React.useContext(GeneralContext)
    const [bundles, setBundles] = React.useState<any>(null);
    const [category, setCategory] = React.useState<any>(null);

    React.useEffect(() => {
        
        if (props.categoryKey) {
            ajax$(calls.Project.getCategoryByKey(props.categoryKey)).subscribe(
                _category => {
                    setCategory(_category)
                    ajax$(calls.Project.getProductProjection('categories.id:"' + _category.id + '"&expand=masterVariant.attributes[*].value[*]'))
                        .subscribe(
                            res => {
                                //setBundles(res.results);
                                const filteredBundles = removeBundleCancelledFromProductProjection(res.results)

                                let x = filteredBundles.map(
                                    (_b:any) => { 
                                        let prices = getBundlePrice(_b, userData, false)

                                        return {
                                            ..._b, 
                                            availability:isBundleAvailable(_b),
                                            price:prices.productsPriceFormatted,
                                            discountPercentValue:prices.discountPercentValue,
                                            customDiscountLabel: customDiscountLabel(_b?.masterVariant?.attributes)
                                        } 
                                    } 
                                )
                                setBundles(x)
                            })
                }

            )
        }
    }, [props.categoryKey]);

    if (!bundles || !category)
        return null;
    else {

        const categoryName = category?.name?.[PROJECT_LANGUAGE]

        return (
            category && bundles?.length > 0 &&
            <StyledBundlesCategory>
                <div className="category-bundles" id={props.categoryKey}>
                    <div className="header">
                        {categoryName && <h2 className="title pb-4">{categoryName}</h2>}
                    </div>
                    
                    <div className="row listItem row products-list">

                        {
                            bundles.map((item: any, i: any) => {
                                return (
                                    <>
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4 product-preview" key={i}>
                                            <BundleItem item={item} available={item.availability.bundleAvailable} productPrice={item.price} discountPercentValue={item.discountPercentValue} i={i} InjectBundle={true} minQta={item.availability.minQta} />
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </StyledBundlesCategory>
        )
    }
}


export const InjectBundleCategoryComponent = () => {
    const [categories, setCategories] = React.useState<any>([]);

    const containerTabs = document.getElementById("bundle-list-tabs");
    React.useEffect(() => {
        const containers = document.querySelectorAll(".reactPlaceholder_listBundles");
        const categoriesKey: any = []
        if (containers) {
            containers.forEach((c: any) => {
                categoriesKey.push(c)
            })
            setCategories(categoriesKey)
        }
    }, []);

    const handleClickScroll = (id:any) => {
        const element = document.getElementById(id);
        if (element) {          
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    if (!categories || !containerTabs)
        return null;
    else {
        return (
            <>
                <div className="row">

                    {categories &&
                        categories.map((item: any) => {
                            const itemCategoryKey = item.getAttribute('data-category-key')
                            return ReactDOM.createPortal(
                                
                                    <li key={itemCategoryKey} className="nav-item">
                                        <a className="nav-link" style={{"textAlign": "left"}} onClick={ () => handleClickScroll(itemCategoryKey)} >{itemCategoryKey.replaceAll("-"," ")}</a>
                                    </li>
                                
                                , containerTabs)
                        })
                    }
                </div>            
                <div className="row">
                    {categories &&
                        categories.map((item: any) => {
                            const itemCategoryKey = item.getAttribute('data-category-key')
                            return ReactDOM.createPortal(
                                <div key={itemCategoryKey}>
                                    <BundleListComponent categoryKey={itemCategoryKey} />
                                </div>
                                , item)
                        })
                    }
                </div>
            </>
        )
    }
}