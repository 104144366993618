import React from "react";
import { SYMBOLS } from "./APIUtils";
import { AWSlogger } from "./rest.context";
import { ISymbolContext } from "../common/common.models";

export const SymbolContext = React.createContext<ISymbolContext>(null!);

export const SymbolContextComponent = (props: any) => {
    const [symbols, setSymbols] = React.useState<any>([]);

    const init = () => {
        if(SYMBOLS !== null && SYMBOLS !== ""){
            setSymbols(JSON.parse(SYMBOLS));
        } else {
            AWSlogger({
                type: "log",
                action: "symbol.context",
                message: "Failed to retrieve symbols from <div> root",
            })
        }
    };

    const getSymbolBySku = (sku: string) => {
        if (symbols && symbols.length > 0) {
            const normalizedSku = sku.trim();

            const symbolsFound = symbols.filter((s: any) => {
                const distinctSkus = s.distinctSkus?.map((sku: string) => sku.trim()) || [];
                return distinctSkus.includes(normalizedSku) && ["symbol", "extended", "label"].includes(s.type);
            });
            return symbolsFound || []
        }
        return []
    }

    React.useEffect(() => {
        init();
    }, []);

    return (
        <SymbolContext.Provider value={{
            getSymbolBySku: getSymbolBySku,
            symbols
        }}>
            {props.children}
        </SymbolContext.Provider>
    );
};