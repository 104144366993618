import * as React from "react";
import {GeneralContext} from "../core/general.context";
import {LabelContext} from "../core/label.context";
import {parsePrice} from "../common/constants";
import {CartContext} from "../core/cart.context";
import {RESTContext} from "../core/rest.context";
import {
    calls,
    COOKIES,
    PROJECT_COUNTRY,
    PROJECT_LANGUAGE,
    PAYMENT_METHODS,
    PROJECT_SETTINGS
} from "../core/APIUtils";
import {getCurrentPrice} from "../components/bundle/utils"
import { YounitedPay } from "../components/younitedPay/younitedPay.component";
import ReactDOM from "react-dom";

export const InjectBehaviour = () => {
    const {cart, wishlist, setOpenBackInStock, userData} = React.useContext(GeneralContext);
    const {ajax$} = React.useContext(RESTContext);
    const {addLineItem, addRemoveWishlistLineItem} = React.useContext(CartContext);
    const addToCartRef = React.useRef<any>(null);
    const openBackInStockListingRef = React.useRef<any>(null);
    const addWishlistRef = React.useRef<any>(null);
    const productDetailLink = React.useRef<any>(null);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const awc = params.get('awc');
    const itemsUnavailable = React.useRef<any>([]);
    const {t} = React.useContext(LabelContext);
    const younitedDiv = document.getElementById('younited');
    const productPrice = younitedDiv ?  (younitedDiv.getAttribute('data-discounted-price') ? younitedDiv.getAttribute('data-discounted-price'): younitedDiv.getAttribute('data-price')) : null;

    React.useEffect(() => {

        if(window.connectif?.managed?.isInitialized()){
            window.connectif.managed.sendEvents([{ type: 'page-visit' }], { entityInfo: {
                islogged: userData?.email ? true : false
            } });
        } else{
            document.addEventListener('connectif.managed.initialized', function onConnectifInitialized() {
                window.connectif.managed.sendEvents([{ type: 'page-visit' }], { entityInfo: {
                    islogged: userData?.email ? true : false
                } });
            });
        }
        
        const activationDate = new Date(PROJECT_SETTINGS?.blackFridayStartDate)
        const currentDate = new Date();
        const isBlackFridayActive = currentDate >= activationDate;

        if(!isBlackFridayActive){
            addOrUpdateStyle('.content-for-guest-inline', 'inline')//symbols
            addOrUpdateStyle('.content-for-guest-grid', 'grid')//banner promo product
            addOrUpdateStyle('.content-for-guest-flex', 'flex')//hero banner e visualbox
            addOrUpdateStyle('.content-for-guest-block', 'block')//promozioni
            addOrUpdateStyle('.content-for-logged-inline', 'none')//symbols
            addOrUpdateStyle('.content-for-logged-grid', 'none')//banner promo product
            addOrUpdateStyle('.content-for-logged-flex', 'none')//hero banner e visualbox
            addOrUpdateStyle('.content-for-logged-block', 'none')//promozioni
            return
        }

        const showContentForVip = userData && userData?.customerGroup?.obj?.key === 'vip-users'

        if (showContentForVip && window.userVisibility === 'guest') {
            window.userVisibility = 'logged'
            window.handleChangeVisibility()
        }
        if(!showContentForVip && window.userVisibility === 'logged') {
            window.userVisibility = 'guest'
            window.handleChangeVisibility()
        }

        document.addEventListener('DOMContentLoaded', () => {
            document.addEventListener('masonryInitialized',handleMasonryInitialized)
        });

        const handleMasonryInitialized = () => {
            if (typeof window.reloadItemsMasonry === 'function') {
                window.reloadItemsMasonry();
            }
        };

        if(showContentForVip){
            addOrUpdateStyle('.content-for-logged-inline', 'inline')//symbols, caroselli react
            addOrUpdateStyle('.content-for-logged-grid', 'grid')//banner promo product, card, herobanner, listing brx, listing promo
            addOrUpdateStyle('.content-for-logged-flex', 'flex')//visualbox, herobanner, pdp
            addOrUpdateStyle('.content-for-logged-block', 'block')//simpleDocumernt, visualbox, pdp, promozioni dettaglio
            addOrUpdateStyle('.content-for-guest-inline', 'none')//symbols
            addOrUpdateStyle('.content-for-guest-grid', 'none')//banner promo product
            addOrUpdateStyle('.content-for-guest-flex', 'none')//hero banner e visualbox
            addOrUpdateStyle('.content-for-guest-block', 'none')//promozioni
        }
        if(!showContentForVip){
            addOrUpdateStyle('.content-for-guest-inline', 'inline')//symbols, caroselli react
            addOrUpdateStyle('.content-for-guest-grid', 'grid')//banner promo product, card, herobanner, listing brx, listing promo
            addOrUpdateStyle('.content-for-guest-flex', 'flex')//visualbox, herobanner, pdp
            addOrUpdateStyle('.content-for-guest-block', 'block')//simpleDocumernt, visualbox, pdp, promozioni dettaglio
            addOrUpdateStyle('.content-for-logged-inline', 'none')//symbols
            addOrUpdateStyle('.content-for-logged-grid', 'none')//banner promo product
            addOrUpdateStyle('.content-for-logged-flex', 'none')//hero banner e visualbox
            addOrUpdateStyle('.content-for-logged-block', 'none')//promozioni
        }

        handleMasonryInitialized()

        return () => {
            window.removeEventListener('masonryInitialized',handleMasonryInitialized)
        };

    },[userData])

    const addOrUpdateStyle = (selector: string, displayValue: string) => {
        // Cerca se esiste già uno stile con il selettore specificato all'interno dei fogli di stile
        let ruleFound = false;

        // Itera su tutti i fogli di stile presenti nella pagina
        Array.from(document.styleSheets).forEach(sheet => {
            try {
                // Itera su tutte le regole di stile del foglio di stile corrente
                Array.from(sheet.cssRules).forEach((rule) => {
                    // Verifica se la regola è una CSSStyleRule e corrisponde al selettore specificato
                    if (rule instanceof CSSStyleRule && rule.selectorText === selector) {
                        // Aggiorna lo stile display con il valore fornito, usando !important
                        rule.style.setProperty('display', displayValue, 'important');
                        ruleFound = true; // Segna che la regola è stata trovata e aggiornata
                    }
                });
            } catch (error) {}
        });

        // Se non è stata trovata alcuna regola con il selettore specificato, crea un nuovo stile
        if (!ruleFound) {
            let style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `${selector} { display: ${displayValue} !important; }`;
            document.head.appendChild(style);
        }
    };


    React.useEffect(() => {


        // integration https://wiki.awin.com/ marketing
        ["DE"].includes(PROJECT_COUNTRY) && awc && COOKIES.set("awc", awc)

        window.initAddToCart = () => {
            document.querySelectorAll('.js_addToCart').forEach((productLink) => {
                productLink.removeEventListener('click', addToCartRef.current);
                productLink.addEventListener('click', addToCartRef.current);
                productLink.classList.remove("hidden");
                if (productLink.parentElement?.classList.contains('isOutOfStock')) {
                    productLink.parentElement?.classList.add('product-link_buy', 'btn')
                    productLink.parentElement?.classList.remove('mx-auto')
                    productLink.parentNode?.removeChild(productLink.parentNode?.children[1])
                }
                if (itemsUnavailable.current && itemsUnavailable.current.length > 0 && itemsUnavailable.current.includes(productLink.getAttribute('data-id'))) {
                    productLink.classList.add("hidden");
                    productLink.parentElement?.classList.remove('product-link_buy', 'btn')
                    productLink.parentElement?.classList.add('isOutOfStock')
                    const node = document.createElement('div')
                    node.classList.add('mx-auto')
                    node.innerHTML = t("ecommerce.cart.maxQuantityReached")
                    productLink.parentElement?.appendChild(node)
                }
            });
        }

        window.externalAddToCart = (id) => {
            addLineItem(id, false)
        }

    }, []);

    React.useEffect(() => {
        if (cart) {
            openBackInStockListingRef.current = (event: any) => {
                if (event) {
                    let imageUrl = event.target.getAttribute('data-image')
                    let sku = event.target.getAttribute('data-sku')
                    let name = event.target.getAttribute('data-name')
                    let title = event.target.getAttribute('data-title')
                    let description = event.target.getAttribute('data-description')
                    setOpenBackInStock({
                        imageUrl: imageUrl,
                        sku: sku,
                        name: name,
                        title: title,
                        description: description
                    })
                    window.openBIS()
                }
            }

            let itemsInCart: string[] = []
            if (cart.lineItems && cart.lineItems.length > 0) {
                cart.lineItems.forEach((lineItem) => {
                    if (lineItem.quantity >= lineItem.variant.availability.availableQuantity && PROJECT_SETTINGS.inventoryMode === "ReserveOnOrder") {
                        itemsInCart.push(lineItem.variant.sku)
                    }
                })
            }
            itemsUnavailable.current = [...itemsInCart]

            productDetailLink.current = (event: any) => {
                if (event) {
                    //event.preventDefault();
                    let sku = event?.currentTarget?.dataset?.productId;


                    ajax$(calls.Project.getProduct(sku || ""))
                        .subscribe((res: any) => {

                            let family = res?.masterVariant?.attributes?.find((attr: any) => attr.name === "family")?.value?.key
                            let price = getCurrentPrice(res?.masterVariant?.prices, userData)?.value
                            let quantity = 1
                            let aesthetics = res?.masterVariant?.attributes?.find((attr: any) => attr.name === "NEW_AESTHETICS")?.value?.label

                            dataLayer.push({
                                event: 'productClick',
                                ecommerce: {
                                    click: {
                                        currencyCode: `${price.currencyCode}`,
                                        actionField: {
                                            'list': 'sitemapName'
                                        },
                                        products: [{
                                            name: `${res?.name?.[PROJECT_LANGUAGE]}`,
                                            id: `${res?.key}`,
                                            price: `${parsePrice(price, true)}`,
                                            brand: `${aesthetics}`,
                                            category: `${family}`,
                                            variant: `${res?.key}`,
                                            quantity: `${quantity}`
                                        }]
                                    }
                                }
                            });
                        })

                }
            }

            document.querySelectorAll('.js_product-link').forEach((productLink) => {
                productLink.removeEventListener('click', productDetailLink.current);
                productLink.addEventListener('click', productDetailLink.current);
            });

            document.querySelectorAll('.js_backInStock').forEach((productLink) => {
                productLink.removeEventListener('click', openBackInStockListingRef.current);
                productLink.addEventListener('click', openBackInStockListingRef.current);
            });


            addToCartRef.current = (event: any) => {
                if (event) {
                    //event.preventDefault();
                    addLineItem(event.target?.getAttribute('data-id'), false)

                    ajax$(calls.Project.getProduct(event.target.dataset.id || ""))
                        .subscribe((res: any) => {
                            let family = res.masterVariant.attributes.find((attr: any) => attr.name === "family")?.value.key
                            let price = getCurrentPrice(res.masterVariant.prices, userData)?.value
                            let quantity = 1
                            let aesthetics = res.masterVariant.attributes.find((attr: any) => attr.name === "NEW_AESTHETICS")?.value.label
                        })
                }
            }

            window.initAddToCart()

            


        }

        return () => {
            document.querySelectorAll('.js_addToCart').forEach((productLink) => {
                productLink.removeEventListener('click', addToCartRef.current);
            });
            document.querySelectorAll('.js_product-link').forEach((productLink) => {
                productLink.removeEventListener('click', productDetailLink.current);
            });
            document.querySelectorAll('.js_backInStock').forEach((productLink) => {
                productLink.removeEventListener('click', openBackInStockListingRef.current);
            });
        };


    }, [cart]);

    React.useEffect(() => {
        if (wishlist) {
            addWishlistRef.current = (event: any) => {
                if (event) {
                    event.preventDefault();
                    // event.stopPropagation();
                    addRemoveWishlistLineItem(event.target?.getAttribute('data-id'))
                }
            }
            document.querySelectorAll('.js_addToWishlist').forEach((productLink) => {

                productLink.removeEventListener('click', addWishlistRef.current);
                productLink.addEventListener('click', addWishlistRef.current);

                let sku = productLink.getAttribute('data-id')

                productLink.classList.remove("inWishlist")

                if (wishlist.lineItems.find((item: any) => item.variant && item.variant.sku === sku)) {
                    productLink.classList.add("inWishlist");
                }

            });
        }
        return () => document.querySelectorAll('.js_addToWishlist').forEach((productLink) => {
                productLink.removeEventListener('click', addWishlistRef.current);
            }
        );
    }, [wishlist]);
    
    if (!younitedDiv || !productPrice)
        return null;
    else
        return ReactDOM.createPortal(
            <>
            {cart && PAYMENT_METHODS?.includes("younited") && <YounitedPay
                cartid={cart.id}
                amount={parseFloat(productPrice).toFixed(2)}
                showPayButton={false}
                setInModal={true}
                /> }
            </>
            , younitedDiv
        );
};