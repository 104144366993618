import styled from "styled-components";

export const StyledLogin = styled.div`
  h4 {
    padding-bottom: 0!important;
  }
  &.easy-login {
    //background-color: var(--color-porcelain);
    height: 100%;
    .centerBlocks {
      max-width: 350px;
      margin: 0 auto;
    }
    .passwordRecovery {
      text-align: right;
    }
  }
  .centerBlocks{
    h4{
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
`;


export const StyledPopupLogin = styled.div`
  .popup {
    position: fixed;
    top: var(--page-margin); /* Aggiungi uno spazio dal bordo superiore */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Allinea gli elementi dall'alto */
    overflow-y: auto; /* Permette lo scroll se il contenuto è troppo alto */
    z-index: 1000;
  }

  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    margin-top: 20px; /* Aggiungi uno spazio interno per spostare ulteriormente il contenuto */
  }

  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: black;
  }

  .popup-nordics{
    @media (min-width: 1536px) {
      top: 150px;
    }
  }
`;
